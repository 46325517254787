import { createAction, props } from '@ngrx/store';

import {
  QuoteRequestsStats,
  QuoteRequestsStatsWidget,
  QuoteRequestsStatsWidgetRequest,
} from '../../models';

export const loadRequest = createAction(
  '[Quote Requests Stats] Load Request',
  props<{ properties: number[] }>(),
);

export const loadSuccess = createAction(
  '[Quote Requests Stats] Load Success',
  props<{ stats: QuoteRequestsStats }>(),
);

export const loadFailure = createAction(
  '[Quote Requests Stats] Load Failure',
  props<{ error: any }>(),
);

export const loadStatsWidgetRequest = createAction(
  '[Quote Requests Stats] Load Stats Widget Request',
  props<{ request: QuoteRequestsStatsWidgetRequest }>(),
);

export const loadStatsWidgetSuccess = createAction(
  '[Quote Requests Stats] Load Stats Widget Success',
  props<{ data: QuoteRequestsStatsWidget }>(),
);

export const loadStatsWidgetFailure = createAction(
  '[Quote Requests Stats] Load Stats Widget Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Quote Requests Stats] Reset State');
